import idEndpointGetter from '@/api/idEndpointGetter'
import streamingApi from '@/api/modules/projects/nodes/streaming'
import maintenanceApi from '@/api/modules/projects/nodes/maintenance'
import powerApi from '@/api/modules/projects/nodes/power'
import useBasicApi from '@/api/useBasicApi'
import config from '@/config'
import { ServerEvent } from '@/enums'

const axios = config.urls.AXIOS_API

export default function nodesApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/nodes`

  return idEndpointGetter({
    ENDPOINT,
    CREATE_EVENT: ServerEvent.NODE_CREATED,
    DELETE_EVENT: ServerEvent.NODE_DELETED,
    UPDATE_EVENT: ServerEvent.NODE_UPDATED,
    create: async ({ name }) =>
      axios.post(`${ENDPOINT}/`, {
        name,
      }),
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    for: nodeId => {
      const NODE_ENDPOINT = `${ENDPOINT}/${nodeId}`
      return {
        ENDPOINT: NODE_ENDPOINT,
        update: async name => axios.put(`${NODE_ENDPOINT}/`, { name }),
        ...useBasicApi(NODE_ENDPOINT, ['get', 'remove']),
        streaming: streamingApi(NODE_ENDPOINT),
        maintenance: maintenanceApi(NODE_ENDPOINT),
        power: powerApi(NODE_ENDPOINT),
      }
    },
  })
}
