import { computed, ref } from 'vue'
import config from '../config'

export const token = ref()
export const keycloak = ref()
export const user = ref()

export const setKeycloak = data => {
  keycloak.value = data
}
export const hasRole = role => user.value?.roles?.includes(role)

export const GlobalRole = Object.freeze({
  SUPERADMIN: 'superadmin',
})

export const isSuperAdmin = computed(() => hasRole(GlobalRole.SUPERADMIN))

export const hasOneOfRoles = roles => {
  return roles?.some(hasRole)
}
export const setToken = data => {
  console.log({ token: data.token, tokenData: data.tokenParsed })
  token.value = data.token
  user.value = { ...data.tokenParsed, id: data.tokenParsed?.sub }
  config.urls.AXIOS_API.defaults.headers.common['Authorization'] = `Bearer ${token.value}`
}

export const updateTokenRef = () => setToken(keycloak.value)

export const signOut = () => keycloak.value?.logout()

export const getProjectKeyFromRole = role => {
  if (!role?.startsWith('project')) return undefined
  const splitted = role.split('.')
  if (splitted.length < 2) return undefined
  return splitted[1]
}

export const constructProjectRole = (projectKey, action) => `project.${projectKey}.${action}`
export const hasProjectAction = (projectKey, action) =>
  hasRole(constructProjectRole(projectKey, action))
export const hasOneOfProjectActions = (projectKey, actions) =>
  actions?.some(it => hasProjectAction(projectKey, it)) || isSuperAdmin.value

export const constructEntityAction = (entity, action) => `${entity}.${action}`
export const hasEntityAction = (projectKey, entity, action) =>
  hasProjectAction(projectKey, constructEntityAction(entity, action)) || isSuperAdmin.value

export const ProjectAction = Object.freeze({
  GUEST: 'guest',
  ADMIN: 'admin',
  ADMIN_UI_ACCESS: 'admin_ui_access',
  MEMBER: 'member',
})

export const Entity = Object.freeze({
  TASK: 'task',
  TASK_PACKAGE: 'task_package',
  TASK_STATUS: 'task_status',
  TASK_STEP: 'task_step',
  TEST_CONFIG: 'test_config',
  NODE: 'node',
  PROJECT: 'project',
  HARDWARE_SETUP: 'hardware_setup',
  BOOKING: 'booking',
  CAPABILITY: 'capability',
  CAPABILITY_INSTANCE: 'capability_instance',
  PROJECT_SETTINGS: 'project.settings',
  PROJECT_SETTINGS_VALUES_FOR_PROJECT: 'project.settings.values.for_project',
  PROJECT_SETTINGS_VALUES_FOR_USER: 'project.settings.values.for_user',
  SYSTEM_SETTINGS_VALUES: 'system.settings.values',
})

export const EntityAction = Object.freeze({
  LIST: 'list',
  GET: 'get',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  DOWNLOAD: 'download',

  CHANGE_TOKEN: 'change_token',
  UPDATE_STATUS: 'update_status',
  MAINTENANCE: 'maintenance',
  REGISTER: 'register',

  STREAMING: 'streaming',
  RUN_STREAMING: 'run_streaming',
  STOP_STREAMING: 'stop_streaming',

  EXECUTE_COMMAND: 'execute_command',
  CAPABILITIES: 'capabilities',
  ADD_CAPABILITY: 'add_capability',
  REMOVE_CAPABILITY: 'remove_capability',

  RUN: 'run',
  ABORT: 'abort',
  AVAILABLE_HWs: 'available_hardware_setup',
  PACKAGE_VERSIONS: 'package_versions',
})
