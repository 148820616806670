import { computed, onMounted, reactive, ref } from 'vue'
import api from '@/api'
import { projectKey } from '@/composables/useProject'
import { service } from '@/services/hardwareSetup'
import { notifyWarning } from '@/composables/useNotifications'
import { getDuration } from '@/utils/time'
import { convert, on } from '@/composables/useServerEvents'
import { user } from '@/composables/useAuth'
import { fetchAll } from '@/composables/useFetchingList'

// you can find visual representation for this algorithm in `./useNearestBookings.mermaid`
const apiBookings = computed(() => api.projects.for(projectKey.value).bookings)
const hwsServ = service.useService()

/* This is storage of closest to current time booking for each hws.
   Used `reactive()` instead of `ref()` to avoid unnecessary .value calls. This is common use case for `reactive()`. */
export const nearest = reactive(new Map())

export const isNotExpired = booking => new Date(booking.end_date) - new Date() > 0
export const isMine = booking => booking?.user_id === user.value.id
export const isLeftEarlier = (cur, newbie) =>
  new Date(cur.start_date) - new Date(newbie.start_date) < 0
export const isCurrent = booking =>
  new Date().betweenEq(new Date(booking.start_date), new Date(booking.end_date))

const injectTimers = async booking => {
  const timeLeftMs = new Date(booking.end_date) - new Date()
  const FIVE_MIN_IN_MS = 5 * 60 * 1000

  // by use-case, only notification for current logged user should be shown
  if (booking.user === user.value.id) {
    const tdName = (await hwsServ.getByKey(booking.hardware_setup_id)).value.name
    const warnMsg = `Booking of ${tdName} hardware setup will end in `
    if (timeLeftMs < FIVE_MIN_IN_MS) notifyWarning(`${warnMsg}${getDuration(timeLeftMs)}`)
    else {
      booking.notificationTimer = setTimeout(
        () => notifyWarning(`${warnMsg}5 minutes`),
        timeLeftMs - FIVE_MIN_IN_MS
      )
    }
  }

  booking.actualizeTimer = setTimeout(
    async () => {
      nearest.delete(booking.hardware_setup_id)
      await actualize(booking.hardware_setup_id)
    },
    timeLeftMs + 5000 // small wait to make API change nearest booking
  )

  if (booking.isCurrent) return
  /* No need to set `isCurrent = false` when booking time expired because:
     a) it will be rewritten by next booking requested from api
     b) it will be deleted
   */
  booking.makeCurrentTimer = setTimeout(
    () => (booking.isCurrent = true),
    new Date(booking.start_date) - new Date()
  )
}

const rejectTimers = booking => {
  clearTimeout(booking?.notificationTimer)
  clearTimeout(booking?.actualizeTimer)
  clearTimeout(booking?.makeCurrentTimer)
}

const store = async booking => {
  if (!booking) return
  booking.isCurrent = isCurrent(booking)
  booking.isMine = isMine(booking)
  booking = ref(booking)
  // do not inject timers until making `ref()` because changes in `makeCurrentTimer()` will not work!
  await injectTimers(booking.value)
  nearest.set(booking.value.hardware_setup_id, booking)
}

const actualize = async (hardware_setup_id = null) => {
  const { get, getNext } = apiBookings.value.nearestNotExpired
  /* this endpoint return only first and nearest for current time booking for requested hws
    (or for each hws if filter is empty) */
  await fetchAll({
    provider: get,
    providerNext: getNext,
    filter: hardware_setup_id ? { hardware_setup_id } : {},
    onChunkLoad: results => results.forEach(store),
  })
}

const onUpdate = async item => {
  item = convert(item)
  const existed = nearest.get(item.hardware_setup_id)?.value
  /* If right now there is no booking for this hws, and newbie (from sse) is not expired -
     it will become nearest and will be saved. */
  if (!existed && isNotExpired(item)) await store(item)
  else {
    /* Need request from api again to avoid problems with next case:
        1. front: bookingA(10:00-10:30)
           back: bookingA(10:00-10:30), bookingB(11:00-11:30)
        2. Someone update bookingA on back: bookingA(10:00-10:30) --> bookingA(12:00-12:30)
           front: bookingA(10:00-10:30)
           back: bookingB(11:00-11:30), bookingA(12:00-12:30)
        3. We just update time of bookingA through SSE
        4. MISTAKE! Not nearest booking is saved.
    */
    rejectTimers(existed)
    await actualize(item.hardware_setup_id)
  }
}

const onCreate = async item => {
  item = convert(item)
  if (!isNotExpired(item)) return
  const existed = nearest.get(item.hardware_setup_id)?.value
  // if new booking is earlier than existed, we can ignore new
  if (existed && isLeftEarlier(existed, item)) return
  if (existed) rejectTimers(existed)
  await store(item)
}

const onDelete = async item => {
  item = convert(item)
  const corpse = nearest.get(item.hardware_setup_id)?.value
  if (!corpse || corpse?.id !== item.id) return
  rejectTimers(corpse)
  nearest.delete(corpse.hardware_setup_id)
  await actualize(item.hardware_setup_id)
}

let init = false

export const use = () => {
  if (init) throw new Error('use() should be called once on app init.')
  onMounted(actualize)
  on(apiBookings.value.CREATE_EVENT, onCreate)
  on(apiBookings.value.UPDATE_EVENT, onUpdate)
  on(apiBookings.value.DELETE_EVENT, onDelete)
  init = true
}

export const useForHWS = hardware_setup_id => {
  const nearestForHWS = computed(() => nearest.get(hardware_setup_id)?.value)

  /* If nearest booking for hws not exists - this hws doesn't have any booking at all.
      If nearest booking for hws exists - that doesn't mean that this booking is current.*/
  const current = computed(() => {
    const nearestForHWSItem = nearestForHWS.value
    return nearestForHWSItem?.isCurrent ? nearestForHWSItem : null
  })

  return {
    nearest: nearestForHWS,
    current,
  }
}
