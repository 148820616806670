// todo: move all funcs to `Date.prototype`
export const getTimeAgo = ({ date, full = false }) => {
  let ms = new Date() - date
  const intervals = [
    { text: 'year', mult_end: true, v: 365 * 24 * 3600 * 1000 },
    { text: 'month', mult_end: true, v: 30 * 24 * 3600 * 1000 },
    { text: 'week', mult_end: true, v: 7 * 24 * 3600 * 1000 },
    { text: 'day', mult_end: true, v: 24 * 3600 * 1000 },
    { text: 'hour', mult_end: true, v: 3600 * 1000 },
    { text: 'minute', mult_end: true, v: 60 * 1000 },
    { text: 'second', mult_end: true, v: 1000 },
    { text: 'ms', mult_end: false, v: 1 },
  ]
  let parts = intervals
    .map(({ v }) => {
      let part = Math.floor(ms / v)
      ms = ms - part * v
      return part
    })
    .map((v, i) => ({ v, ending: v > 1 && intervals[i].mult_end ? 's' : '' }))
    .map(({ v, ending }, i) => ({ text: `${v} ${intervals[i].text}${ending}`, v }))
    .filter(({ v }) => v > 0)
    .map(({ text }) => text, 'just now')
  let result = full ? parts.reduce((acc, cur) => `${acc} ${cur}`) : parts[0]
  return result === 'just now' ? result : `${result} ago`
}

Date.prototype.toMonthDay = function () {
  const options = { month: 'short', day: 'numeric' }
  return this.toLocaleDateString('en-US', options)
}

export const getMonthDayString = date => {
  if (date instanceof Date) {
    return date.toMonthDay()
  }
  return ''
}

export const getDuration = (ms = 0, useText = false) => {
  ms ??= 0
  ms = Math.round(ms * 100) / 100
  if (ms < 1000) return `${ms} ms`
  const intervals = [
    { text: 'h', v: 3600 * 1000 },
    { text: 'min', v: 60 * 1000 },
    { text: 's', v: 1000 },
  ]
  const parts = intervals.map(({ v, text }) => {
    let part = Math.floor(ms / v)
    ms = ms - part * v
    return { v: part, text }
  })
  if (useText) {
    return parts
      .filter(({ v }) => v > 0)
      .map(({ v, text }) => `${v} ${text}`)
      .join(' ')
  }
  return parts.map(({ v }) => (`${v}`.length < 2 ? `0${v}` : `${v}`)).join(':')
}

export const getDurationString = ({ start, end }) => {
  if (start && end) {
    return getDuration(new Date(end) - new Date(start))
  }
  return ''
}

export const getDateTimeString = (date, full) => {
  let timeStr = `${date.toLocaleDateString()} ${date.getHours()}:${('0' + date.getMinutes()).slice(
    -2
  )}`
  if (full) {
    timeStr += `:${('0' + date.getSeconds()).slice(-2)}.${('00' + date.getMilliseconds()).slice(
      -2
    )}`
  }
  return timeStr
}

export const getDateString = date => `${date.toLocaleDateString()}`
export const getTimeString = date => `${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`

export const getDiffUpdated = it => Date.now() - new Date(it?.updated_at).getTime()
